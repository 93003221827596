import { toast } from 'react-toastify';
toast.configure();

const ToastInfo = (message) => {
  toast.info(message, { position: toast.POSITION.BOTTOM_RIGHT });
}

const ToastSuccess = (message) => {
  toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
}

const ToastError = (message) => {
  toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
}

const ToastWarning = (message) => {
  toast.warning(message, { position: toast.POSITION.BOTTOM_RIGHT });
};

export { ToastInfo, ToastSuccess, ToastError, ToastWarning };