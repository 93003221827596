import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import ApiHandling from '../../../utils/ApiHandling';
import { apiUrl } from '../../../utils/constants';
import { timeAgo } from '../../../utils/commonFunctions';

//i18n
import { withTranslation } from 'react-i18next';

const NotificationDropdown = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem('authUser'));

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = () => {
    ApiHandling.fetchApi(
      `${apiUrl}/notification/allNotifications?${
        decodedToken.userType === 'builder'
          ? 'builderId=' + decodedToken.logId
          : decodedToken.userType === 'agent'
          ? 'agentId=' + decodedToken.logId
          : ''
      }`
    ).then((response) => {
      if (response?.data) {
        response.data.notificationDetails &&
          setNotifications(
            response.data.notificationDetails.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            )
          );
      }
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const unseenNotificationCount =
    notifications.length -
    (parseInt(localStorage.getItem('notificationSeenCount')) || 0);
  const isNotificationsSeen = unseenNotificationCount === 0;

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
          onClick={() => {
            localStorage.setItem('notificationSeenCount', notifications.length);
          }}
        >
          <i
            className={`bx bx-bell ${isNotificationsSeen ? '' : 'bx-tada'}`}
          ></i>
          {isNotificationsSeen || unseenNotificationCount <= 0 ? null : (
            <span className="badge badge-danger badge-pill">
              {unseenNotificationCount}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t('Notifications')} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: '230px' }}>
            {notifications.slice(0, 5).map((notification) => {
              return (
                <Link
                  to={
                    notification.link || notification.type === 'KYC_REQUEST'
                      ? '/bbUser/kycDetails'
                      : notification.type === 'NEW_JOB' &&
                        decodedToken.userType === 'agent'
                      ? '/agent/jobs'
                      : '#'
                  }
                  className="text-reset notification-item"
                >
                  <div className="media">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-person"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">
                        {props.t(notification.notificationText)}
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {props.t('Type')} - {props.t(notification.type)}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i>{' '}
                          {props.t(timeAgo(notification.createdAt))}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </SimpleBar>
          <div className="p-2 border-top">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notifications"
            >
              {' '}
              {props.t('View all')}{' '}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);
