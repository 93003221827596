import React, { useEffect, useState } from 'react';
import { Row, Col, CardBody, Card, Container } from 'reactstrap';

// action
import {
  registerUser,
  apiError,
  registerUserFailed,
} from '../../store/actions';

// Redux
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

// import images
import profileImg from '../../assets/images/profile-img.png';
import { apiUrl } from '../../utils/constants';
import ApiHandling from '../../utils/ApiHandling';
import { ToastInfo } from '../../components/Common/Toast';
import { InputText } from 'primereact/inputtext';

const RegisterAgent = (props) => {
  const history = useHistory();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [signUpData, setSignUpData] = useState({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    password: '',
    confirmPassword: '',
  });

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let userData = { ...signUpData };
    signUpData[`${name}`] = val;
    setSignUpData({
      ...signUpData,
      userData,
    });
  };

  // handleValidSubmit
  const signUp = () => {
    if (
      signUpData.firstName &&
      signUpData.lastName &&
      signUpData.mobileNumber &&
      signUpData.password &&
      signUpData.password === signUpData.confirmPassword
    ) {
      let currentApiUrl = `${apiUrl}/agent/signup`;
      const data = {};

      data.agentDetails = {
        mobileNumber: signUpData.mobileNumber,
        firstName: signUpData.firstName,
        lastName: signUpData.lastName,
        password: signUpData.password,
      };

      ApiHandling.fetchApi(currentApiUrl, data, 'POST').then((response) => {
        if (response?.status === 200) {
          setIsOtpSent(true);
        }
      });
    } else {
      if (isOtpSent && (otp === '' || otp.length !== 6)) {
        ToastInfo('Please Enter Valid OTP');
      } else if (signUpData.password !== signUpData.confirmPassword) {
        ToastInfo('Passwords does not match');
      } else {
        ToastInfo('Please fill all the details');
      }
    }
  };

  const verifyOtp = () => {
    if (otp) {
      let currentApiUrl = `${apiUrl}/agent/verify/otp?mobileNumber=${signUpData.mobileNumber}&otp=${otp}`;
      ApiHandling.fetchApi(currentApiUrl, {}, 'POST').then((response) => {
        if (response?.status === 200) {
          props.history.push('/login');
        }
      });
    } else ToastInfo('Please fill the otp');
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back Agent!</h5>
                        <p>Sign up to continue to Realtor.works.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody>
                  <div className="p-2">
                    <div className="p-field col-12">
                      <label htmlFor="firstName">First Name</label>
                      <br />
                      <InputText
                        disabled={isOtpSent}
                        placeholder="Please Enter First Name"
                        className="w-100"
                        id="firstName"
                        value={signUpData.firstName}
                        onChange={(e) => onInputChange(e, 'firstName')}
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="p-field col-12">
                      <label htmlFor="lastName">Last Name</label>
                      <InputText
                        disabled={isOtpSent}
                        placeholder="Please Enter Last Name"
                        className="w-100"
                        id="lastName"
                        value={signUpData.lastName}
                        onChange={(e) => onInputChange(e, 'lastName')}
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="p-field col-12">
                      <label htmlFor="mobileNumber">Mobile Number</label>
                      <InputText
                        disabled={isOtpSent}
                        type="number"
                        placeholder="Please Enter Mobile Number"
                        className="w-100"
                        id="mobileNumber"
                        value={signUpData.mobileNumber}
                        onChange={(e) => onInputChange(e, 'mobileNumber')}
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="p-field col-12">
                      <label htmlFor="password">Password</label>
                      <InputText
                        type="password"
                        disabled={isOtpSent}
                        placeholder="Please Enter Password"
                        className="w-100"
                        id="password"
                        value={signUpData.password}
                        onChange={(e) => onInputChange(e, 'password')}
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="p-field col-12">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <InputText
                        type="password"
                        disabled={isOtpSent}
                        placeholder="Please Enter Confirm Password"
                        className="w-100"
                        id="confirmPassword"
                        value={signUpData.confirmPassword}
                        onChange={(e) => onInputChange(e, 'confirmPassword')}
                      />
                    </div>
                  </div>
                  {isOtpSent ? (
                    <div className="p-2">
                      <div className="p-field col-12">
                        <label htmlFor="otp">Otp</label>
                        <InputText
                          type="number"
                          placeholder="Please Enter Otp"
                          className="w-100"
                          id="otp"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                    </div>
                  ) : null}
                  <Row className="form-group mt-4">
                    <Col className="text-right mr-3">
                      {isOtpSent ? (
                        <button
                          onClick={() => verifyOtp()}
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Verify Otp
                        </button>
                      ) : (
                        <button
                          onClick={() => signUp()}
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Sign Up
                        </button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{' '}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(RegisterAgent);
