import {
  SET_COUNTRIES,
  SET_USER_ROLES,
  SET_INACTIVE_LEADS,
  CLEAR_INACTIVE_LEADS,
} from './actionTypes';

const initialState = {
  userRoles: [],
  countries: [],
  inactiveLeads: null,
  cntDropdownOptions: [],
};

const MiscellaneousReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ROLES:
      state = {
        ...state,
        userRoles: action.payload,
      };
      break;
    case SET_COUNTRIES:
      state = {
        ...state,
        countries: action.payload.countries,
        cntDropdownOptions: action.payload.cntDropdownOptions,
      };
      break;
    case SET_INACTIVE_LEADS:
      state = {
        ...state,
        inactiveLeads: action.payload,
      };
      break;
    case CLEAR_INACTIVE_LEADS:
      state = {
        ...state,
        inactiveLeads: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MiscellaneousReducer;
