import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, CardBody, Card, Container } from 'reactstrap';

import { Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import profile from '../../assets/images/profile-img.png';
import ApiHandling from '../../utils/ApiHandling';
import { apiUrl } from '../../utils/constants';
import OtpInput from 'react-otp-input';
import { ToastInfo } from '../../components/Common/Toast';

const ForgetPasswordPage = (props) => {
  const [userType, setUserType] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [isVerifyPhoneNo, setIsVerifyPhoneNo] = useState(false);
  const mobileNumberRef = useRef();

  useEffect(() => {
    const paramsObject = new URLSearchParams(props.location.search);
    if (paramsObject.get('userType')) {
      setUserType(
        paramsObject.get('userType').charAt(0).toLowerCase() +
          paramsObject.get('userType').substring(1)
      );
    }
    if (props.history.location.pathname === '/verifyPhoneNo') {
      setIsVerifyPhoneNo(true);
    }
  }, []);

  const onFormSubmit = (event, invalidFields, values) => {
    console.log('form submit calling');
    if (!invalidFields.length) {
      let product = {
        ...values,
        userType,
      };

      if (isOtpSent)
        product = {
          ...product,
          otp,
        };
      if (isVerifyPhoneNo) {
        if (!isOtpSent || (isOtpSent && otp && otp.length === 6)) {
          let requestType = isOtpSent ? 'POST' : 'PUT';
          let currentApiUrl = isOtpSent
            ? `${apiUrl}/global/verify/otp/verifyPhn?mobileNumber=${values.mobileNumber}&otp=${otp}`
            : `${apiUrl}/global/resend/otp/verifyPhn?mobileNumber=${values.mobileNumber}`;
          ApiHandling.fetchApi(currentApiUrl, {}, requestType)
            .then((response) => {
              if (response?.status === 200) {
                if (isOtpSent || response?.data) {
                  let data = response.data;
                  if (!isOtpSent) {
                    setIsOtpSent(true);
                    if (data) {
                      if (data.USERTYPE === 'agent') {
                        setUserType('agent');
                      } else if (data.USERTYPE === 'builder') {
                        setUserType('builder');
                      } else if (data.USERTYPE === 'marketingAgency') {
                        setUserType('marketingAgency');
                      }
                    }
                  } else {
                    props.history.push('/login');
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          if (isOtpSent && (otp === '' || otp.length !== 6)) {
            ToastInfo('Please Enter Valid OTP');
          }
          if (values.password !== values.confirmPassword) {
            ToastInfo('Passwords does not match');
          }
        }
      } else if (
        !isOtpSent ||
        (isOtpSent &&
          otp &&
          values.password === values.confirmPassword &&
          otp.length === 6)
      ) {
        let requestType = isOtpSent ? 'POST' : 'PUT';
        let currentApiUrl = isOtpSent
          ? `${apiUrl}/global/verifyOtp/forgotPwd`
          : `${apiUrl}/global/otp/forgot/password?mobileNumber=${values.mobileNumber}`;
        ApiHandling.fetchApi(
          currentApiUrl,
          { profileDetails: product },
          requestType
        ).then((response) => {
          if (response?.status === 200) {
            if (!isOtpSent) {
              setIsOtpSent(true);
            } else {
              props.history.push('/login');
            }
          }
        });
      } else {
        if (isOtpSent && (otp === '' || otp.length !== 6)) {
          ToastInfo('Please Enter Valid OTP');
        }
        if (values.password !== values.confirmPassword) {
          ToastInfo('Passwords does not match');
        }
      }
    }
  };

  const resendOtpHandler = (event) => {
    event.preventDefault();
    const mobileNumber = mobileNumberRef.current.value;
    if (mobileNumber) {
      let requestType = 'PUT';
      let currentApiUrl = isVerifyPhoneNo
        ? `${apiUrl}/global/resend/otp/verifyPhn?mobileNumber=${mobileNumber}`
        : `${apiUrl}/global/otp/forgot/password?mobileNumber=${mobileNumber}`;
      ApiHandling.fetchApi(currentApiUrl, {}, requestType).then((response) => {
        if (response?.status === 200) {
        }
      });
    } else {
      ToastInfo('Please enter mobile number');
    }
  };

  const onOtpChange = (value) => {
    setOtp(value);
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back Buddy!</h5>
                        <p>Change password to access Realtor.works.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal mt-4"
                      onSubmit={onFormSubmit}
                    >
                      <div className="form-group">
                        <label htmlFor="mobileNumber" required>
                          Mobile Number
                        </label>
                        <AvField
                          name="mobileNumber"
                          className="form-control"
                          placeholder="Enter Mobile number"
                          type="number"
                          required
                          disabled={isOtpSent}
                          validate={{
                            pattern: {
                              value: /^\d{10}$/,
                              errorMessage:
                                'Please enter a valid 10-digit mobile number.',
                            },
                          }}
                          innerRef={mobileNumberRef}
                        />
                        {isOtpSent && (
                          <>
                            {!isVerifyPhoneNo && (
                              <>
                                <label htmlFor="password" required>
                                  Password
                                </label>
                                <AvField
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  placeholder="Enter Password"
                                  required
                                />
                                <label htmlFor="confirmPassword" required>
                                  Confirm Password
                                </label>
                                <AvField
                                  name="confirmPassword"
                                  type="password"
                                  className="form-control"
                                  placeholder="Confirm Password"
                                  required
                                />
                              </>
                            )}
                            <label htmlFor="otp" required>
                              OTP
                            </label>
                            <OtpInput
                              value={otp}
                              onChange={onOtpChange}
                              numInputs={6}
                              isInputNum={true}
                              className="otp-input-field"
                            />
                          </>
                        )}
                      </div>
                      <Row className="form-group mt-4">
                        <Col className="text-right">
                          {isOtpSent && (
                            <button
                              style={{
                                color: '#004A87',
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                marginRight: '5px',
                                transition: 'color 0.3s',
                                fontWeight: '500',
                              }}
                              onMouseOver={(e) =>
                                (e.target.style.color = '#002E5D')
                              }
                              onMouseOut={(e) =>
                                (e.target.style.color = '#004A87')
                              }
                              onClick={(e) => resendOtpHandler(e)}
                              type="button"
                            >
                              Resend otp
                            </button>
                          )}
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            {!isOtpSent
                              ? 'Send OTP'
                              : isVerifyPhoneNo
                              ? 'Verify Phone Number'
                              : 'Change Password'}
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{' '}
                  <Link
                    to="pages-login"
                    className="font-weight-medium text-primary"
                  >
                    Login
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordPage;
