// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBok0Ajtw9BQQ5d6nUHSnTZyIM3E50N3Wc",
  authDomain: "crmbbapp.firebaseapp.com",
  projectId: "crmbbapp",
  storageBucket: "crmbbapp.appspot.com",
  messagingSenderId: "535051346932",
  appId: "1:535051346932:web:0ec0355aa026096e81cfa1",
};

console.log("process.env", process.env);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
