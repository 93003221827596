import React from 'react';

import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError } from '../../../store/actions';

// import images
import profile from '../../../assets/images/profile-img.png';

const Login = (props) => {
  // handleValidSubmit
  function handleValidSubmit(event, values) {
    values['userType'] = 'bbUser';
    props.loginUser(values, props.history);
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="login-container account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={7} lg={6} xl={5} className="offset-md-5 offset-lg-6">
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: 'rgba(0, 95, 173, 0.33)' }}>
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Realtor.works.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 mt-4">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.error && props.error ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="mobileNumber"
                          label="Mobile Number"
                          placeholder="Enter Mobile Number"
                          type="text"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="mt-5">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link
                          to={{
                            pathname: '/forgot-password',
                            search: 'userType=bbUser',
                            state: props.history.location,
                          }}
                          className="text-muted"
                        >
                          <i className="mdi mdi-lock mr-1"></i> Forgot your
                          password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don't have an account ?{' '}
                  <Link
                    to="register"
                    className="font-weight-medium text-primary"
                  >
                    {' '}
                    Signup now{' '}
                  </Link>{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(Login)
);
