import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

//i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// users
import user1 from '../../../assets/images/users/avatar-1.jpg';

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [decodedToken, setDecodedToken] = useState({});
  const [username, setusername] = useState('Admin');

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'));
      setDecodedToken(obj);
      setusername(obj.firstName + ' ' + obj.lastName);
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {decodedToken.profilePic ? (
            <img
              className="rounded-circle header-profile-user"
              src={decodedToken.profilePic ? decodedToken.profilePic : user1}
              alt="Header Avatar"
              style={{ width: '42px', height: '42px' }}
            />
          ) : (
            <div
              className=" rounded-circle"
              style={{
                minWidth: '42px',
                minHeight: '42px',
                background: '#005fad',
                color: '#fff',
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: '42px',
                fontWeight: '500',
              }}
            >
              <span>
                {(decodedToken.firstName?.slice(0, 1).toUpperCase() || '') +
                  (decodedToken?.lastName?.slice(0, 1).toUpperCase() || '')}
              </span>
            </div>
          )}
          <div
            style={{
              display: 'inline-block',
              verticalAlign: 'bottom',
              textAlign: 'left',
            }}
          >
            <span className="d-xl-inline-block ml-2 mr-1">{username}</span>
            <i className="mdi mdi-chevron-down d-xl-inline-block"></i>
            <p style={{ textTransform: 'uppercase' }} className="ml-2 mb-0">
              <span style={{ fontSize: '11px' }}>
                {decodedToken.userType +
                  (decodedToken.userType !== 'agent'
                    ? ' - ' + decodedToken.userRole
                    : '')}
              </span>
            </p>
          </div>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="a" href="/profile">
            {' '}
            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
            {props.t('Profile')}{' '}
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
