import { fork, put, all, take, call, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
  GET_COUNTRIES,
  GET_USER_ROLES,
  GET_INACTIVE_LEADS,
} from './actionTypes';
import { setUserRoles, setCountries, setInactiveLeads } from './actions';
import ApiHandling from '../../utils/ApiHandling';
import { apiUrl } from '../../utils/constants';

function* getUserRoles() {
  try {
    let response = yield ApiHandling.fetchApi(`${apiUrl}/global/role`);
    if (response?.data) {
      const data = response.data;
      let userRoles = [];
      data.forEach((userRole) => {
        userRoles.push({ label: userRole.name, value: userRole.name });
      });
      yield put(setUserRoles(userRoles));
    }
  } catch (error) {}
}

function* getCountries() {
  try {
    let response = yield ApiHandling.fetchApi(
      `${apiUrl}/bbUser/master/country`
    );
    if (response?.data) {
      const data = response.data;
      let countries = [];
      data.forEach((country) => {
        if (country.status === 1)
          countries.push({ label: country.name, value: country.cntId });
      });
      yield put(
        setCountries({ countries: data, cntDropdownOptions: countries })
      );
    }
  } catch (error) {}
}

function* getIncativeLeads() {
  try {
    const decodedToken = JSON.parse(localStorage.getItem('authUser'));
    if (decodedToken.userType === 'agent') {
      let response = yield ApiHandling.fetchApi(
        `${apiUrl}/jobMapping/getInactiveLeads`
      );
      if (response.data) {
        yield put(setInactiveLeads(response.data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetUserRoles() {
  yield takeEvery(GET_USER_ROLES, getUserRoles);
}

export function* watchGetCountries() {
  yield takeEvery(GET_COUNTRIES, getCountries);
}

export function* watchGetInactiveLeads() {
  yield takeEvery(GET_INACTIVE_LEADS, getIncativeLeads);
}

function* miscellaneousSaga() {
  yield all([
    fork(watchGetUserRoles),
    fork(watchGetCountries),
    fork(watchGetInactiveLeads),
  ]);
}

export default miscellaneousSaga;
