import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

// Profile
import UserProfile from '../pages/Authentication/user-profile';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import RegisterDummy from '../pages/Authentication/RegisterDummy';
import RegisterAgent from '../pages/Authentication/RegisterAgent';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import Register from '../pages/Authentication/Register';

// BBUser Authentication
import BBUserLogin from '../pages/BBUser/BBUserAuthentication/Login';

//utilities
import Pages404 from '../pages/Utility/pages-404';
import Pages500 from '../pages/Utility/pages-500';

//BBuser Pages
const BBUserAgents = lazy(() => import('../pages/BBUser/agents'));
const BBUserBuilders = lazy(() => import('../pages/BBUser/Builders'));
const BBUserMarketingAgencies = lazy(() =>
  import('../pages/BBUser/MarketingAgencies')
);
const BBUserContacts = lazy(() => import('../pages/BBUser/Contacts'));
const Projects = lazy(() => import('../pages/BBUser/Projects'));
const KycDetails = lazy(() => import('../pages/BBUser/KycDetails'));

//BBuser master - location pages
const MasterCountry = lazy(() =>
  import('../pages/BBUser/Master/Location/MasterCountry')
);
const MasterState = lazy(() =>
  import('../pages/BBUser/Master/Location/MasterState')
);
const MasterCity = lazy(() =>
  import('../pages/BBUser/Master/Location/MasterCity')
);

//BBuser master - languages page
const MasterLanguages = lazy(() =>
  import('../pages/BBUser/Master/MasterLanguages')
);

//BBuser master - Amenities and Specifications pages
const AmenitiesAndSpecs = lazy(() =>
  import('../pages/BBUser/Master/AmenitiesAndSpecs')
);

// Agent pages
const AgentJobs = lazy(() => import('../pages/Agent/AgentJobs'));
const AgentAppliedJobs = lazy(() => import('../pages/Agent/AgentAppliedJobs'));
const AgentProjects = lazy(() => import('../pages/Agent/Projects'));
const AgentBuilders = lazy(() =>
  import('../../src/pages/Agent/Agent_Builders')
);

// Builder Pages
const BuilderProjects = lazy(() => import('../pages/Builder/Projects'));

// Marketing Agency Pages
const MA_Builders = lazy(() => import('../pages/Marketing Agency/MA_Builders'));
const MarketingAgencyProjects = lazy(() =>
  import('../pages/Marketing Agency/Projects')
);

//common pages
const Users = lazy(() => import('../pages/CommonPages/Users'));
const Contacts = lazy(() => import('../pages/CommonPages/Contacts'));
const NewProjectForm = lazy(() =>
  import('../pages/CommonPages/NewProjectForm')
);
const ProjectDocumentsUpload = lazy(() =>
  import('../pages/CommonPages/ProjectDocumentsUpload')
);
const ProjectDetails = lazy(() =>
  import('../pages/CommonPages/ProjectDetails')
);
const NewJobForm = lazy(() => import('../pages/CommonPages/NewJobForm'));
const DocumentsUpload = lazy(() =>
  import('../pages/CommonPages/DocumentsUpload')
);
const Jobs = lazy(() => import('../pages/CommonPages/Jobs'));
const JobApplications = lazy(() =>
  import('../pages/CommonPages/JobApplications')
);
const SendBuilderRequest = lazy(() =>
  import('../pages/CommonPages/SendBuilderRequest')
);
const AgentRequests = lazy(() => import('../pages/Builder/AgentRequests'));
const Leads = lazy(() => import('../pages/CommonPages/Leads'));

const BuilderAgents = lazy(() => import('../pages/Builder/BuilderAgents'));

const DuplicateContacts = lazy(() =>
  import('../pages/CommonPages/DuplicateContacts')
);
const Dashboard = lazy(() => import('../pages/CommonPages/Dashboard'));
const Notifications = lazy(() => import('../pages/Notifications'));

const userRoutes = [
  //profile
  { path: '/profile', component: UserProfile },

  //common pages
  { path: '/:userType/users', component: Users },
  { path: '/:id/dashboard', component: Dashboard },
  { path: '/:id/contacts', component: Contacts },
  { path: '/:id/documentsUpload', component: DocumentsUpload },
  {
    path: '/:id/projects/projectForm/documentsUpload',
    component: ProjectDocumentsUpload,
  },
  { path: '/:id/projects/projectForm', component: NewProjectForm },
  { path: '/:id/project/jobs/jobForm', component: NewJobForm },
  { path: '/:id/leads', component: Leads },
  { path: '/:id/duplicateContacts', component: DuplicateContacts },

  //bbuser pages
  { path: '/bbUser/agents', component: BBUserAgents },
  { path: '/bbUser/builders', component: BBUserBuilders },
  { path: '/bbUser/marketingAgencies', component: BBUserMarketingAgencies },
  { path: '/bbUser/bbUserContacts', component: BBUserContacts },
  { path: '/bbUser/projects', component: Projects },
  { path: '/bbUser/kycDetails', component: KycDetails },
  //bbuser master - location pages
  { path: '/bbUser/master/country', component: MasterCountry },
  { path: '/bbUser/master/state', component: MasterState },
  { path: '/bbUser/master/city', component: MasterCity },
  //bbuser master - location pages
  { path: '/bbUser/master/languages', component: MasterLanguages },
  //bbuser master - Amenities and Specifications pages
  { path: '/bbUser/master/amenity', component: AmenitiesAndSpecs },
  { path: '/bbUser/master/specification', component: AmenitiesAndSpecs },

  //agent pages
  { path: '/agent/jobs', component: AgentJobs },
  { path: '/agent/appliedJobs', component: AgentAppliedJobs },
  { path: '/agent/projects', component: AgentProjects },
  { path: '/agent/sendRequestToBuilder', component: SendBuilderRequest },
  { path: '/agent/builders', component: AgentBuilders },
  //Builder pages
  { path: '/builder/project/jobs', component: Jobs },
  { path: '/builder/projects', component: BuilderProjects },
  { path: '/builder/jobApplications', component: JobApplications },
  { path: '/builder/agentRequests', component: AgentRequests },
  { path: '/builder/agents', component: BuilderAgents },

  //Marketing Agency pages
  { path: '/marketingAgency/builders', component: MA_Builders },
  { path: '/marketingAgency/project/jobs', component: Jobs },
  { path: '/marketingAgency/projects', component: MarketingAgencyProjects },
  { path: '/marketingAgency/jobApplications', component: JobApplications },

  { path: '/notifications', component: Notifications },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/bbUser/users" /> },
];

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/verifyPhoneNo', component: ForgetPwd },
  // { path: "/register", component: RegisterDummy },
  { path: '/register', component: Register },
  { path: '/registerAgent', component: RegisterAgent },

  { path: '/pages-404', component: Pages404 },
  { path: '/pages-500', component: Pages500 },

  // BBUser Authentication
  { path: '/bblogin', component: BBUserLogin },

  //Project Details Page
  { path: '/projectDetails', component: ProjectDetails },
];

export { userRoutes, authRoutes };
